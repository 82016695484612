<template>
  <div id="typeSalary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">CONFIGURACIÓN</v-row>
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol">
            <v-row>
              <div class="form-group mt-7 mb-5">
                <label
                  for="area"
                  style="width: 48%; display: inline-block; margin-left: -22%"
                  >Áreas de atención de servicio a clientes y usuarios</label
                >
                <select name="area" id="area" v-model="area" class="mt-n5">
                  <option disabled value="0">Selecciona</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" class="mt-12">
            <button class="botonAmarillo mt-5" @click="validacion()">
              Guardar
            </button>
            <router-link to="/questions" id="backSalary">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Áreas de atención</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Áreas de atención</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de agregar el área?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Configuración de Área</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      salary: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      area: 0,
      areas: [],
      areaAux: {},
      idEmpresa: "",
      areaNombre: "",
      cortoNombre: "",
      nivel: "",
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validacion: function () {
      this.errors = [];
      if (this.area == 0) {
        this.errors.push("Se debe especificar el área.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      this.area = 0;
      this.advice = false;
    },
    aux() {
      this.confirmation = false;
      this.area = 0;
      this.$router.push("/questions");
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .get(Server + "/areas/" + this.area, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.areaAux = response.data;
          //console.log(this.areaAux);
          this.idEmpresa = this.areaAux.empresaId;
          this.areaNombre = this.areaAux.nombreArea;
          this.cortoNombre = this.areaAux.nombreAreaCorto;
          this.nivel = this.areaAux.nivelJerarquico;
          this.show = false;
          this.updateArea();
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreas() {
      this.show = true;
      //console.log("areas puestos");
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == localStorage.empresaIdGlobal) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    updateArea() {
      this.show = true;
      axios
        .put(
          Server + "/areas/" + this.area,
          {
            Id: this.area,
            EmpresaId: this.idEmpresa,
            NombreArea: this.areaNombre,
            NombreAreaCorto: this.cortoNombre,
            NivelJerarquico: this.nivel,
            AreaServicioCliente: true,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.area = 0;
          this.show = false;
          this.$router.push("/configurationArea");
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.obtenerAreas();
  },
};
</script>